<template>
	<div class="full-height flex-column justify-space-between">
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit"> {{ program.title }} </h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div
			class="section_wrap pt-80 size-px-14"
		>
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_box_1 justify-space-between">

						<button
							@click="onTab('in')"
							class="flex-1 btn_l mr-10"
							:class="tab_type == 'in' ? 'btn_fill_blue' : 'btn_fill_gray'"
						>{{ $language.subscribe.title_subscribe_member }} <!-- 구독 멤버 --></button>

						<button
							@click="onTab('out')"
							class="flex-1 btn_l "
							:class="tab_type == 'out' ? 'btn_fill_blue' : 'btn_fill_gray'"
						>{{ $language.subscribe.title_subscribe_member_finish }} <!-- 구독 종료 --></button>
					</div>
					<div class="subindex_box_1">
						<select
							class="input-box pa-10-20"
						>
							<option>{{ $language.subscribe.title_subscribe_plan_list }} <!-- 전체 구독 플랜 --></option>
						</select>
					</div>
					<div class="subindex_box_1">
						<div class="input-box pa-10-20 justify-space-between items-center">
							<input v-model="item_member.nickname" :placeholder="$language.common.search_member" class="flex-1" maxlength="20"/>
							<v-icon
								v-if="item_member.nickname"
								class="mr-10 size-px-20"
								@click="item_member.nickname = ''"
							>mdi-close-circle</v-icon>
							<v-icon>mdi-magnify</v-icon>
						</div>
					</div>
					<div class="subindex_box_1">
						<div class="justify-space-between">
							<h4>{{ $language.subscribe.title_subscribe_member }} <!-- 구독 멤버 --></h4>
							<span>{{ tot_subscrp_end_member_count | makeComma }}명</span>
						</div>
						<ul
							class="mt-30"
							v-if="items_member.length > 0"
						>
							<li
								v-for="(item, index) in items_member"
								:key="'item_' + index"
								class="justify-space-between items-center mb-10"
								@click="toDetail(item)"
							>
								<div class="img-box-64 mr-10 radius-100"><img :src="item.profle_img_file_path" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/></div>
								<div class="flex-1 ">
									<div>{{ item.nickname }}</div>
									<div class="mtb-5">{{ item.subscrp_plan_name }}</div>
									<div>{{ item.subscrp_enddate }}</div>
								</div>
								<div>
									<button
										class="btn_s btn_outline_blue"
									>{{ $language.subscribe.btn_subscribe_list }} <!-- 결제내역 --></button>
								</div>
							</li>
						</ul>
						<Empty
							v-else
						></Empty>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Empty from "@/view/Layout/Empty";
export default {
	name: 'SubscribeMember'
	, components: {Empty}
	, props: ['user']
	, data: function(){
		return {

			program: {
				name: this.$language.notice.add
				, title: '구독 회원 관리'
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'

			}
			, item_cartel: {

			}
			, item_member: {
				
			}
			, items_member: [
				{
					nickname: '닉네임'
					, subscrp_plan_name: '결제유형코드'
					, subscrp_enddate: '2023.05.31 종료'
					, profle_img_file_path: ''
					, subscrp_member_number: '12'
				}
				, {
					nickname: '1'
					, subscrp_plan_name: 'MAF'
					, subscrp_enddate: 'BC00100002'
					, coin_token_code: 'BC00200001'
					, profle_img_file_path: ''
					, subscrp_member_number: '12'
				}
				, {
					nickname: '1'
					, subscrp_plan_name: 'MAF'
					, subscrp_enddate: 'BC00100002'
					, coin_token_code: 'BC00200002'
					, profle_img_file_path: ''
					, subscrp_member_number: '12'
				}
			]
			, tab_type: 'in'
			, tot_subscrp_end_member_count: 12357
		}
	}
	, computed: {

	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getInMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_in_member
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, nickname: this.item_member.nickname
					}
					, type: true
				})

				if(result.success){
					this.tot_subscrp_end_member_count = result.data.tot_subscrp_member_count
					this.items_member = result.data.subscrp_member_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}


		, getOutMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_out_member
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, nickname: this.item_member.nickname
					}
					, type: true
				})

				if(result.success){
					this.tot_subscrp_end_member_count = result.data.tot_subscrp_end_member_count
					this.items_member = result.data.subscrp_end_member_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, goBack: function(){
			this.$bus.$emit('goBack')
		}
		, onTab: function(type){
			this.tab_type = type
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'SubscribeHistory', params: { idx: this.$route.params.idx, m_id: item.subscrp_member_number}})
		}
	}
	, async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getInMember()
	}
	, watch: {
		tab_type: {
			handler: function(call){
				if(call == 'in'){
					this.getInMember()
				}else{
					this.getOutMember()
				}
			}
		}
	}

}
</script>